import { Workout, WorkoutInput, WorkoutStatus } from "../__generated__/graphql";
import { Modal, Button } from "semantic-ui-react";
import React, { FC } from "react";
import { convertSectionToString } from "../converters/sectionConverters";
import _ from "lodash";
import { TextComponent } from "./TextComponent";

type WorkoutSummaryModalProps = {
  workout: Workout | WorkoutInput;
  onClose: () => void;
  handleConfirm: (status?: WorkoutStatus) => void;
  handleNegative?: () => void;
  showNegative?: boolean;
  confirmText?: string;
  negativeText?: string;
};

const selectStatusUpdateOptions = (
  status: WorkoutStatus
): [string, WorkoutStatus | undefined] => {
  switch (status) {
    case WorkoutStatus.NEW:
      return ["Approve Workout", WorkoutStatus.APPROVED];
    case WorkoutStatus.APPROVED:
      return ["UnApprove Workout", WorkoutStatus.NEW];
    case WorkoutStatus.DELETED:
      return ["Reset Workout", WorkoutStatus.NEW];
    case WorkoutStatus.UNKNOWN:
      return ["", undefined];
  }
};
export const WorkoutSummaryModal: FC<WorkoutSummaryModalProps> = ({
  workout,
  onClose,
  showNegative,
  confirmText,
  negativeText,
  handleConfirm,
  handleNegative,
}) => {
  const currentStatus =
    "status" in workout ? workout.status : WorkoutStatus.UNKNOWN;
  const workoutSectionText = workout.sections.map((section) =>
    convertSectionToString(section)
  );

  const [statusUpdateText, toggledStatus] =
    selectStatusUpdateOptions(currentStatus);

  return (
    <Modal dimmer={true} open={!!workout}>
      <Modal.Header>Workout Summary: {workout.name || workout.id}</Modal.Header>
      <Modal.Content>
        <TextComponent
          text={_.join(workoutSectionText, "\n\nThen\n")}
        ></TextComponent>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Close</Button>
        {showNegative && handleNegative && (
          <Button
            type="button"
            negative={true}
            onClick={() => handleNegative()}
          >
            {negativeText || "Delete Workout"}
          </Button>
        )}
        <Button
          type="button"
          primary={true}
          onClick={() => handleConfirm(toggledStatus)}
        >
          {confirmText || statusUpdateText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WorkoutSummaryModal;
