import { capitalize } from "lodash";
import React, { FC, Fragment, useMemo } from "react";
import { Form, Card, Icon } from "semantic-ui-react";
import { convertEnumForDropdown } from "../formatters/enumConverter";
import {
  ExerciseTextConfig,
  getExerciseTypeConfig,
} from "../formatters/exerciseTypeFormatter";
import { ExerciseOnChange, InputExercise } from "../types/InputWorkout";
import { ExerciseType, LoadType, RepType } from "../__generated__/graphql";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

type ExerciseFormProps = {
  exercise: InputExercise;
  onChange: ExerciseOnChange;
  onDelete: () => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
};

export const ExerciseForm: FC<ExerciseFormProps> = ({
  exercise,
  onChange,
  onDelete,
  dragHandleProps,
}) => {
  const isIncreasingReps = exercise.repType === RepType.INC_REPS;
  const isDuration = [RepType.SECONDS].includes(exercise.repType as RepType);
  const repLabel = `${capitalize(exercise.repType) || "Rep"}${
    [RepType.SECONDS, RepType.INC_REPS].includes(exercise.repType as RepType)
      ? ""
      : "s"
  }`;

  const exerciseConfig: ExerciseTextConfig | undefined = useMemo(
    () => (exercise.type ? getExerciseTypeConfig(exercise.type) : undefined),
    [exercise.type]
  );

  const onTypeChange = (type: ExerciseType) => {
    const newConfig = getExerciseTypeConfig(type);

    onChange("type", type);

    if (newConfig.useHeight) {
      onChange("loadType", LoadType.BODYWEIGHT);
    }
  };

  return (
    <Card fluid={true} className="raised p-3 my-3">
      <Fragment>
        <div className="d-flex justify-content-end">
          <div
            className="d-flex justify-content-center"
            style={{
              flexBasis: "33%",
            }}
            {...dragHandleProps}
          >
            <Icon name="bars" size="large" />
          </div>

          <div style={{ flexBasis: "33%" }}>
            <Icon
              className="float-right"
              name="trash"
              size="large"
              onClick={onDelete}
            />
          </div>
        </div>

        <Form.Select
          name="type"
          label="Exercise Type"
          value={exercise.type}
          search={true}
          onChange={(_, d) => onTypeChange(d.value as ExerciseType)}
          options={convertEnumForDropdown(ExerciseType)}
        />
        <Form.Select
          name="repType"
          label="Rep Type"
          value={exercise.repType}
          onChange={(_, d) => onChange("repType", d.value as RepType)}
          options={convertEnumForDropdown(RepType)}
        />

        {isIncreasingReps && (
          <Form.Group>
            <Form.Input
              type="number"
              name="reps"
              label="Start Rep"
              value={exercise.reps}
              onChange={(_, { value }) => onChange("reps", parseInt(value))}
            />
            <Form.Input
              type="number"
              name="repStep"
              label="Rep Step"
              value={exercise.repStep}
              onChange={(_, { value }) => onChange("repStep", parseInt(value))}
            />
            <Form.Input
              type="number"
              name="finalReps"
              label="Final Rep"
              value={exercise.finalRep}
              onChange={(_, { value }) => onChange("finalRep", parseInt(value))}
            />
          </Form.Group>
        )}

        {/* afeiltodo there is probably something here to clean up a bit */}
        {!isIncreasingReps && !isDuration && (
          <Form.Input
            type="number"
            name="reps"
            label={repLabel}
            value={exercise.reps}
            onChange={(_, { value }) => onChange("reps", parseInt(value))}
          />
        )}

        {!isIncreasingReps && isDuration && (
          <Form.Input
            type="number"
            name="reps"
            label={repLabel}
            value={exercise.duration}
            onChange={(_, { value }) => onChange("duration", parseInt(value))}
          />
        )}

        <Form.Select
          name="loadType"
          label="Load Type"
          placeholder="Select Load Type"
          pointing="top"
          value={exercise.loadType}
          onChange={(_, d) => onChange("loadType", d.value as LoadType)}
          options={convertEnumForDropdown(LoadType)}
        />
        {exercise.loadType === LoadType.WEIGHT && (
          <Fragment>
            <Form.Input
              type="number"
              name="load"
              label="Weight(in lbs)"
              value={exercise.load && Math.round(exercise.load * 2.205)}
              onChange={(_, { value }) =>
                onChange("load", parseInt(value) / 2.205)
              }
            />
            <Form.Input
              type="number"
              name="load"
              label="Womens Weight(in lbs)"
              value={
                exercise.womensLoad && Math.round(exercise.womensLoad * 2.205)
              }
              onChange={(_, { value }) =>
                onChange("womensLoad", parseInt(value) / 2.205)
              }
            />
          </Fragment>
        )}

        {exerciseConfig?.useHeight && (
          <Fragment>
            <Form.Input
              type="number"
              name="height"
              label="Height(in inches)"
              value={exercise.height}
              onChange={(_, { value }) => onChange("height", parseInt(value))}
            />

            <Form.Input
              type="number"
              name="womensHeight"
              label="Womens Height(in inches)"
              value={exercise.womensHeight}
              onChange={(_, { value }) =>
                onChange("womensHeight", parseInt(value))
              }
            />
          </Fragment>
        )}
      </Fragment>
    </Card>
  );
};
