import { convertExcerciseToString, convertExerciseToInput } from './exerciseConverters';
import { Section, SectionInput, WorkoutType } from "../__generated__/graphql";
import { getDurationTextForType } from './durationConverter';
import { InputSection } from '../types/InputWorkout';

const getSectionHeaderText = (section: Section | SectionInput): string => {

    switch (section.type) {
        case WorkoutType.AMRAP:
            return `AMRAP ${section.duration}`;
        case WorkoutType.EMOM:
            // afeiltodo: need to account for the interval case
            return `EMOM ${section.duration}`;
        case WorkoutType.LADDER:
            return section.duration ? `For ${section.duration} Minutes` : 'For Time';
        case WorkoutType.ROUNDS_FOR_TIME:
            return `${section.duration} Rounds For Time`;
        case WorkoutType.TIMED:
            return `For ${section.duration} Minutes`;
        case WorkoutType.FOR_TIME:
            return 'For Time';
        case WorkoutType.REST:
            return `Rest ${section.duration} ${getDurationTextForType(section.durationType)}`;
        case WorkoutType.CASH_IN:
            return 'Cash-in';
        case WorkoutType.CASH_OUT:
            return 'Cash-out';
    }

}
export const convertSectionToString = (section: Section | SectionInput): string => {
    const typeString = getSectionHeaderText(section);
    const exerciseString = convertExcerciseToString(section.exercises);

    return `${typeString}\n${exerciseString ? '\n' + exerciseString : ''}`;
}

export const convertSectionToInput = (section: Section, index: number): InputSection => {

    return {
        displayName: `Section ${index + 1}`,
        duration: section.duration,
        durationType: section.durationType,
        interval: section.interval,
        intervalType: section.intervalType,
        type: section.type,
        exercises: section.exercises.map(e => convertExerciseToInput(e)),
    }
}

export const convertLocalInputSectionToSectionInput = (section: InputSection): SectionInput => {
    // eslint-disable-next-line
    const { displayName, ...rest } = section;
    return rest as SectionInput;
}