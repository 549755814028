import { gql, useQuery } from "@apollo/client";
import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import {
  WorkoutStatus,
  Workout,
  SortOption,
  WorkoutFiltersInput,
  SortDirection,
} from "../__generated__/graphql";
import WorkoutTable from "./WorkoutTable";
import {
  Message,
  Dimmer,
  Loader,
  Grid,
  Sidebar,
  Segment,
} from "semantic-ui-react";
import { FilterSidebar } from "./FilterSidebar";
import { FilterOnChange } from "../types/InputWorkoutFilters";

const GET_FILTERED_WORKOUTS = gql`
  query getFilteredWorkouts($filters: WorkoutFiltersInput!) {
    getFilteredWorkouts(filters: $filters) {
      id
    }
  }
`;
type WorkoutStatusTableProps = {
  statuses: WorkoutStatus[];
  test?: string;
};
export const WorkoutStatusTable: FC<WorkoutStatusTableProps> = (
  props: WorkoutStatusTableProps
) => {
  const [filters, setFilters] = useState<WorkoutFiltersInput>({
    status: props.statuses,
    sort: { option: SortOption.CREATED, direction: SortDirection.DESC },
  });

  const { loading, data, error, refetch } = useQuery(GET_FILTERED_WORKOUTS, {
    variables: {
      filters,
    },
  });

  useEffect(() => {
    refetch();
  }, [filters]);

  const updateFilters: FilterOnChange = (prop, value) => {
    setFilters({
      ...filters,
      [prop]: value,
    });
  };

  const workoutIds = useMemo(
    () => data?.getFilteredWorkouts.map((w: Workout) => w.id),
    [data]
  );

  return (
    <Fragment>
      {error && (
        <Message negative={true}>
          <Message.Header>Problem loading Workouts</Message.Header>
          <p>{error.message}</p>
        </Message>
      )}

      <Dimmer active={loading}>
        <Loader indeterminate>Loading Workouts</Loader>
      </Dimmer>

      <Sidebar.Pushable className="mt-0" as={Segment}>
        <FilterSidebar
          filters={filters}
          onFilterChange={updateFilters}
        ></FilterSidebar>
        <Sidebar.Pusher>
          <Grid className="workout-table-container" columns={1}>
            <Grid.Row>
              <Grid.Column>
                {workoutIds && (
                  <WorkoutTable workoutIds={workoutIds}></WorkoutTable>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Fragment>
  );
};
