import _ from "lodash";
import React, { FC, Fragment, useState } from "react";
import {
  Form,
  Container,
  Button,
  InputOnChangeData,
  Message,
} from "semantic-ui-react";
import { InputWorkout, InputSection, SectionKeys } from "../types/InputWorkout";
import { SectionForm } from "./SectionForm";
import { gql, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./StrictModeDroppable";
import WorkoutSummaryModal from "./WorkoutSummaryModal";
import { convertLocalInputWorkoutToWorkoutInput } from "../converters/workoutConverter";

const CREATE_WORKOUT = gql`
  mutation CreateWorkout($workout: WorkoutInput!) {
    addWorkout(workout: $workout)
  }
`;

type AddWorkoutFormProps = {
  workout?: InputWorkout;
};

export const AddWorkoutForm: FC<AddWorkoutFormProps> = () => {
  const { state } = useLocation();
  const startingWorkout =
    (state && state.workout) ||
    ({
      sections: [],
    } as InputWorkout);
  const [workout, setWorkout] = useState<InputWorkout>(startingWorkout);
  const [showPreview, setShowPreview] = useState(false);

  const resetForm = () => {
    setWorkout({ name: null, notes: null, sections: [] });
  };
  const [addWorkout, { data, loading, error }] = useMutation(CREATE_WORKOUT, {
    refetchQueries: "all",
    onCompleted: () => resetForm(),
  });

  const addNewSection = () => {
    const section: InputSection = {
      exercises: [],
      displayName: `Section ${workout.sections.length + 1}`,
    };
    setWorkout((p) => ({
      ...p,
      sections: [...p.sections, section],
    }));
  };

  const updateWorkout = <T extends keyof InputWorkout>(
    prop: T,
    value: InputWorkout[T]
  ) => {
    setWorkout({
      ...workout,
      [prop]: value,
    });
  };

  const deleteSection = (index: number) => {
    const updatedSections = workout.sections.filter((_, i) => i !== index);
    const updatedNames = recalculateSectionNames(updatedSections);
    setWorkout({
      ...workout,
      sections: updatedNames,
    });
  };

  const updateSection = <T extends SectionKeys>(
    index: number,
    path: T,
    value: InputSection[T]
  ) => {
    const updatedWorkout: InputWorkout = _.set(
      workout,
      `sections.${index}.${path}`,
      value
    );
    setWorkout({ ...updatedWorkout });
  };

  const cloneSection = (index: number) => {
    const section = workout.sections[index];
    const clonedSection: InputSection = {
      ...section,
      displayName: `${section.displayName}(Clone)`,
    };

    const copyOfSections = [...workout.sections];
    copyOfSections.splice(index + 1, 0, clonedSection);
    setWorkout({
      ...workout,
      sections: copyOfSections,
    });
  };

  const handleSubmit = () => {
    const workoutToSubmit = convertLocalInputWorkoutToWorkoutInput(workout);
    setShowPreview(false);
    addWorkout({ variables: { workout: workoutToSubmit } });
  };

  const reorderSections = (startIndex: number, endIndex: number) => {
    const result = Array.from(workout.sections);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const recalculateSectionNames = (
    sections: InputSection[]
  ): InputSection[] => {
    const updatedSections = sections.map((s, index) => ({
      ...s,
      displayName: `Section ${index + 1}`,
    }));

    return updatedSections;
  };

  const onSectionDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const updatedOrder = reorderSections(
      result.source?.index,
      result.destination?.index
    );

    const updatedNames = recalculateSectionNames(updatedOrder);

    setWorkout({
      ...workout,
      sections: updatedNames,
    });
  };

  return (
    <Fragment>
      <Container style={{ paddingBottom: 75 }}>
        <Form
          onSubmit={() => setShowPreview(true)}
          loading={loading}
          error={!!error}
          success={!!data}
        >
          <Message
            error
            header="Failed to submit workout"
            content={error?.message}
          />

          <Message
            success
            header="Added a new Workout!"
            content={data?.addWorkout}
          />
          <Form.Input
            label="Name"
            type="text"
            value={workout.name}
            onChange={(_, d: InputOnChangeData) =>
              updateWorkout("name", d.value)
            }
          ></Form.Input>
          <Form.TextArea
            label="Scoring Instructions"
            type="text"
            value={workout.notes || undefined}
            onChange={(_, d) => updateWorkout("notes", d.value as string)}
          ></Form.TextArea>
          {/* afeiltodo make sections draggable */}
          {/* afeiltodo include a drag handle */}
          <Form.Field name="sections">
            <DragDropContext onDragEnd={onSectionDragEnd}>
              <StrictModeDroppable droppableId="sectionDrop">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {workout.sections.map((section, index) => (
                      <Draggable
                        key={"" + index}
                        index={index}
                        draggableId={"" + index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={provided.draggableProps.style}
                          >
                            <SectionForm
                              key={index}
                              section={section}
                              onChange={(path, value) =>
                                updateSection(index, path, value)
                              }
                              onDelete={() => deleteSection(index)}
                              onClone={() => cloneSection(index)}
                              dragHandleProps={
                                provided.dragHandleProps || undefined
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </DragDropContext>

            <Button type="button" primary={true} onClick={addNewSection}>
              Add Section
            </Button>
          </Form.Field>
        </Form>
        {showPreview && (
          <WorkoutSummaryModal
            confirmText="Submit Workout"
            handleConfirm={() => handleSubmit()}
            workout={convertLocalInputWorkoutToWorkoutInput(workout)}
            onClose={() => setShowPreview(false)}
          />
        )}
      </Container>
      <footer className="w-100 bg-light py-3 navbar fixed-bottom">
        <Container>
          <Button
            className="float-right"
            type="button"
            primary={true}
            onClick={() => setShowPreview(true)}
          >
            Show Preview
          </Button>
        </Container>
      </footer>
    </Fragment>
  );
};
