import { ExerciseType, RepType } from './../__generated__/graphql';
import _ from 'lodash'

export type ExerciseTextConfig = {
    name: string,
    useHeight: boolean,
    textFormat: (reps: number, repType: RepType) => string,
    maxTextFormat: (repType: RepType) => string
}
export const getExerciseTypeConfig = (type?: ExerciseType): ExerciseTextConfig => {
    let customConfig: Partial<ExerciseTextConfig> & Pick<ExerciseTextConfig, 'name'>;
    switch (type) {
        case ExerciseType.AIR_SQUAT:
            customConfig = {
                name: 'Air Squats',
            }
            break;
        case ExerciseType.ASSAULT_BIKE:
            customConfig = {
                name: 'Assault Bike',
                textFormat: cals => `${cals} cal bike`,
                maxTextFormat: repType => repType === RepType.CALORIE ? 'Max Cals Bike' : 'Max Meters Bike'
            }
            break;
        case ExerciseType.ROW:
            customConfig = {
                name: 'Row',
                textFormat: (reps, repType) => repType === RepType.CALORIE ? `${reps} Cal Row` : `${reps} Meter Row`,
                maxTextFormat: repType => repType === RepType.CALORIE ? 'Max Cal Row' : 'Max Distance Row'
            }
            break;
        case ExerciseType.BACK_SQUAT:
            customConfig = {
                name: 'Back Squats',
            }
            break;
        case ExerciseType.BOX_JUMP:
            customConfig = {
                name: 'Box Jumps',
                useHeight: true
            }
            break;
        case ExerciseType.BOX_JUMP_OVER:
            customConfig = {
                name: 'Box Jump Overs',
                useHeight: true
            }
            break;
        case ExerciseType.CLUSTER:
            customConfig = {
                name: 'Clusters'
            }
            break;
        case ExerciseType.DB_CLUSTER:
            customConfig = {
                name: 'Dumbbell Clusters'
            }
            break;
        case ExerciseType.REST:
            customConfig = {
                name: 'Rest',
                textFormat: () => 'Rest',
                maxTextFormat: () => 'Rest',
            }
            break;
        default: {
            const name = _.chain(type).replace('DB_', 'Dumbbell_').replace('KB_', 'Kettlebell_').replace('_AND_', '_&_').split('_').join(' ').lowerCase().startCase().value();
            customConfig = {
                name
            }
        }

    }

    return {
        textFormat: reps => `${reps} ${customConfig.name}`,
        maxTextFormat: () => `Max ${customConfig.name}`,
        useHeight: false,
        ...customConfig
    }
}