import { DurationType } from './../__generated__/graphql';

export const getDurationTextForType = (type?: DurationType): string => {
    switch (type) {
        case DurationType.MINUTES:
            return 'Minutes';
        case DurationType.SECONDS:
            return 'Seconds';
        case DurationType.ROUNDS:
            return 'Rounds';
        default:
            return '';
    }
}
