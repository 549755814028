import React, { FC } from "react";
import {
  ExerciseKeys,
  InputExercise,
  InputSection,
  SectionKeys,
} from "../types/InputWorkout";
import { Card, Form, Button, Icon } from "semantic-ui-react";
import {
  DurationType,
  LoadType,
  RepType,
  WorkoutType,
} from "../__generated__/graphql";
import { convertEnumForDropdown } from "../formatters/enumConverter";
import { ExerciseForm } from "./ExerciseForm";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  DropResult,
} from "react-beautiful-dnd";
import { set } from "lodash/fp";
import { StrictModeDroppable } from "./StrictModeDroppable";

type SectionFormProps = {
  section: InputSection;
  onChange: <T extends SectionKeys>(path: T, value: InputSection[T]) => void;
  onDelete: () => void;
  onClone: () => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
};

export const SectionForm: FC<SectionFormProps> = ({
  section,
  onChange,
  onDelete,
  onClone,
  dragHandleProps,
}) => {
  const addExercise = () => {
    onChange("exercises", [
      ...section.exercises,
      { loadType: LoadType.WEIGHT, repType: RepType.REP },
    ]);
  };

  const reorderExercises = (startIndex: number, endIndex: number) => {
    const result = Array.from(section.exercises);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const deleteExercise = (index: number) => {
    const updatedExercises = section.exercises.filter((_, i) => i !== index);
    onChange("exercises", [...updatedExercises]);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    onChange(
      "exercises",
      reorderExercises(result.source?.index, result.destination?.index)
    );
  };

  const updateExercise = <T extends ExerciseKeys>(
    index: number,
    path: T,
    value: InputExercise[T]
  ) => {
    const updatedExercises = set(`${index}.${path}`, value, section.exercises);
    onChange("exercises", [...updatedExercises]);
  };

  //   afeiltodo: lift the card display up a level
  return (
    <Card fluid={true} className="raised p-3 mb-3">
      <div className="d-flex mb-3">
        <div className="d-flex" style={{ flexBasis: "33%" }}>
          <b>{section.displayName}</b>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{
            flexBasis: "33%",
          }}
          {...dragHandleProps}
        >
          <Icon name="bars" size="large" />
        </div>

        <div style={{ flexBasis: "34%" }}>
          <Icon
            className="float-right"
            name="trash"
            size="large"
            onClick={onDelete}
          />
          <Icon
            className="float-right"
            name="clone"
            size="large"
            onClick={onClone}
          />
        </div>
      </div>
      <Form.Select
        name="type"
        label="Workout Type"
        value={section.type}
        onChange={(_, d) => onChange("type", d.value as WorkoutType)}
        options={convertEnumForDropdown(WorkoutType)}
      />
      <Form.Select
        name="durationType"
        label="Duration Type"
        value={section.durationType}
        onChange={(_, d) => onChange("durationType", d.value as DurationType)}
        options={convertEnumForDropdown(DurationType)}
      />
      <Form.Input
        name="duration"
        label="Duration"
        type="number"
        value={section.duration}
        onChange={(_, d) => onChange("duration", d.value as unknown as number)}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="sectionDrop">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {section.exercises.map((e, index) => (
                <Draggable
                  key={"" + index}
                  index={index}
                  draggableId={"" + index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={provided.draggableProps.style}
                    >
                      <ExerciseForm
                        exercise={e}
                        onChange={(prop, value) =>
                          updateExercise(index, prop, value)
                        }
                        onDelete={() => deleteExercise(index)}
                        dragHandleProps={provided.dragHandleProps || undefined}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>

      <Button type="button" primary={true} onClick={addExercise}>
        Add Exercise
      </Button>
    </Card>
  );
};
