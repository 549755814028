import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { onError } from "@apollo/client/link/error";
  
  
/**
 * ApolloProvider with the client
 */
export default function ApolloClientProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
    const [token, setToken] = useState<string | null>(null);

    const { getAccessTokenSilently, logout, isAuthenticated } = useAuth0();

    const handleNetworkError = () => {
      if(isAuthenticated) {
        logout();
      }
    }

    const updateToken = async () => {
      try {
        const token = isAuthenticated ?  await getAccessTokenSilently() : "";
      setToken(token);
      } catch(e) {
        console.log('failred to get token', e);
      }
      
    } ;

    useEffect(() => {
      updateToken();
    }, [isAuthenticated]);


    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
          handleNetworkError();
        }

        // eslint-disable-next-line
        if((networkError as any)?.statusCode == 401) {
          console.log('got unauthorized response');
        }
      });

    const client = React.useMemo(() => {
        const httpLink = createHttpLink({
            uri: process.env.REACT_APP_API_URL,
          })
          const authLink = setContext(async (_, { headers }) => {
            return {
              headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
              }
            }
          });
        return new ApolloClient({
            link: from([errorLink, authLink, httpLink]),
            cache: new InMemoryCache(),
        
          });
       }, [token]);

       if (token == null) {
        return <p>Loading...</p>
      }
       
       return <ApolloProvider client={client}>{children}</ApolloProvider>;
}