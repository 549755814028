import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import 'semantic-ui-css/semantic.min.css';
import ApolloClientProvider from './components/ApolloClientProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
const authDomain = process.env.REACT_APP_AUTH_URL || '';
const authClientId = process.env.REACT_APP_AUTH_CLIENT_ID || '';

root.render(
  <Auth0Provider
    domain={authDomain}
    clientId={authClientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: 'https://api.metconmaker.com/',
      scope: "email approve:workouts unapprove:workouts delete:workouts read:all_workouts create:workouts"
    }}>
    <ApolloClientProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>

    </ApolloClientProvider>
  </Auth0Provider>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
