import { DropdownItemProps } from 'semantic-ui-react';

export const convertEnumForDropdown = <E, K extends string>(v: { [key in K]: E }): DropdownItemProps[] => {

    const result = Object.keys(v).map(v => ({
        key: v,
        value: v,
        text: v,
    }));
    return result;
}
