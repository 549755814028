import "./App.css";
import { BrowserRouter, NavLink, Routes, Route } from "react-router-dom";
import { Button, Menu } from "semantic-ui-react";
import { AddWorkoutForm } from "./components/AddWorkoutForm";
import React from "react";
import { WorkoutStatusTable } from "./components/WorkoutStatusTable";
import { WorkoutStatus } from "./__generated__/graphql";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <BrowserRouter>
      <div className="d-flex flex-column h-100">
        <h2>Metcon Maker Control Panel</h2>
        <Menu>
          <Menu.Item>
            <NavLink to="/">Home</NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to="/add_workout">Add Workout</NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to="/approvals">Approve Workouts</NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to="/deleted">Deleted Workouts</NavLink>
          </Menu.Item>
          <Menu.Item>
            {!isAuthenticated && (
              <Button
                type="button"
                primary={true}
                onClick={() => loginWithRedirect()}
              >
                Log In
              </Button>
            )}
            {isAuthenticated && (
              <div>
                Logged in as {user?.email} (
                <a className="click-text" onClick={handleLogout}>
                  Logout?
                </a>
                )
              </div>
            )}
          </Menu.Item>
        </Menu>
        {
          <div className="flex-grow-1">
            <Routes>
              <Route
                path="/"
                element={
                  <WorkoutStatusTable statuses={[WorkoutStatus.APPROVED]} />
                }
              />
              <Route path="/add_workout" element={<AddWorkoutForm />} />
              <Route
                path="/approvals"
                element={<WorkoutStatusTable statuses={[WorkoutStatus.NEW]} />}
              />
              <Route
                path="/deleted"
                element={
                  <WorkoutStatusTable statuses={[WorkoutStatus.DELETED]} />
                }
              />
            </Routes>
          </div>
        }
      </div>
    </BrowserRouter>
  );
}

export default App;
