import { Workout, WorkoutInput } from "../__generated__/graphql"
import { InputWorkout } from "../types/InputWorkout"
import { convertLocalInputSectionToSectionInput, convertSectionToInput } from "./sectionConverters"

export const convertWorkoutToInput = (workout: Workout): InputWorkout => {
    // eslint-disable-next-line
    const { name, notes, teamSize, ...rest } = workout
    return {
        name,
        notes,
        teamSize,
        sections: workout.sections.map((s, index) => convertSectionToInput(s, index))
    }

}

export const convertLocalInputWorkoutToWorkoutInput = (workout: InputWorkout): WorkoutInput => {
    return {
        ...workout,
        sections: workout.sections.map(s => convertLocalInputSectionToSectionInput(s))
    }
}