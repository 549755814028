import React, { FC, Fragment } from "react";

type TextComponentProps = {
  text: string;
};

export const TextComponent: FC<TextComponentProps> = ({ text }) => {
  const result = text.split("\n").map((value, index) => (
    <Fragment key={index}>
      {value}
      <br />
    </Fragment>
  ));

  return <>{result}</>;
};
