import React, { FC, useMemo, useState } from "react";
import { Workout, Scalars, WorkoutStatus } from "../__generated__/graphql";
import {
  Dimmer,
  Table,
  Loader,
  Pagination,
  PaginationProps,
  Container,
  Button,
} from "semantic-ui-react";
import capitalize from "lodash/capitalize";
import _ from "lodash";
import { gql, useMutation, useQuery } from "@apollo/client";
import { WorkoutSummaryModal } from "./WorkoutSummaryModal";
import { convertWorkoutToInput } from "../converters/workoutConverter";
import { useNavigate } from "react-router-dom";

const GET_WORKOUTS = gql`
  query GetWorkouts($ids: [UUID!]!) {
    getWorkouts(ids: $ids) {
      id
      name
      status
      notes
      createdTimestamp
      sections {
        id
        type
        duration
        durationType
        exercises {
          type
          load
          loadType
          reps
          repType
          duration
          womensLoad
          height
          womensHeight
        }
      }
    }
  }
`;

const UPDATE_WORKOUT_STATUS = gql`
  mutation updateWorkoutStatus($workoutId: UUID!, $status: WorkoutStatus!) {
    updateWorkoutStatus(workoutId: $workoutId, status: $status)
  }
`;

type TableProps = {
  workoutIds: Scalars["UUID"][];
  pageSize?: number;
};

// afeiltodo: need to separate out pagination
// afeiltodo: should this have a separate query or should this work with a single query on filtered workouts?
const WorkoutTable: FC<TableProps> = ({ workoutIds, pageSize = 10 }) => {
  const navigate = useNavigate();
  const [selectedWorkout, setSelectedWorkout] = useState<Workout>();
  const [pageNum, setPageNum] = useState<number>(0);
  const [workouts, setWorkouts] = useState<Workout[]>([]);
  const pageCount =
    Math.floor(workoutIds.length / pageSize) +
    (workoutIds.length % pageSize ? 1 : 0);
  const selectedWorkoutIds = useMemo(() => {
    return _.chain(workoutIds)
      .drop(pageNum * pageSize)
      .take(pageSize)
      .value();
  }, [pageNum, pageSize, workoutIds]);

  const { loading, error } = useQuery(GET_WORKOUTS, {
    variables: { ids: selectedWorkoutIds },
    onCompleted: (data) => setWorkouts(data.getWorkouts),
  });

  if (error) {
    <p>Error: {error.message}</p>;
  }

  const cloneWorkout = (workout: Workout) => {
    const clonedWorkout = convertWorkoutToInput(workout);
    navigate("/add_workout", { state: { workout: clonedWorkout } });
  };

  const closeModal = () => setSelectedWorkout(undefined);
  // afeiltodo: should use the error for update status
  const [updateWorkoutStatus, { loading: updatingStatus }] = useMutation(
    UPDATE_WORKOUT_STATUS,
    {
      refetchQueries: "all",
      onCompleted: closeModal,
    }
  );

  const onPageChange = (
    event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) => {
    if (data.activePage) {
      setPageNum((data.activePage as number) - 1);
    }
  };

  const headers = ["name", "status", "section_count", "created", "clone"];

  return (
    <Container fluid={true} className="center aligned">
      <Table celled>
        <Table.Header>
          <Table.Row>
            {headers.map((header) => (
              <Table.HeaderCell key={header}>
                {capitalize(header)}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Dimmer.Dimmable as={Table.Body} blurring={true}>
          {workouts.map((workout: Workout) => (
            <Table.Row
              key={workout.id}
              onClick={() => setSelectedWorkout(workout)}
            >
              <Table.Cell>{workout.name || `id: ${workout.id}`}</Table.Cell>
              <Table.Cell>{workout.status}</Table.Cell>
              <Table.Cell>{(workout.sections || []).length}</Table.Cell>
              <Table.Cell>
                {/* afeiltodo need to handle custom scalars in codegen */}
                {new Date(parseInt(workout.createdTimestamp)).toLocaleString()}
              </Table.Cell>
              <Table.Cell>
                <Button primary={true} onClick={() => cloneWorkout(workout)}>
                  Clone
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
          {selectedWorkout && (
            <WorkoutSummaryModal
              workout={selectedWorkout}
              onClose={closeModal}
              showNegative={selectedWorkout.status !== WorkoutStatus.DELETED}
              handleConfirm={(status) =>
                updateWorkoutStatus({
                  variables: { workoutId: selectedWorkout.id, status },
                })
              }
              handleNegative={() =>
                updateWorkoutStatus({
                  variables: {
                    workoutId: selectedWorkout.id,
                    status: WorkoutStatus.DELETED,
                  },
                })
              }
            />
          )}
        </Dimmer.Dimmable>
        <Dimmer active={loading || updatingStatus}>
          <Loader size="huge" />
        </Dimmer>
      </Table>

      <Pagination
        totalPages={pageCount}
        activePage={pageNum + 1}
        onPageChange={onPageChange}
      />
    </Container>
  );
};

export default WorkoutTable;
