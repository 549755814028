import React, { FC } from "react";
import {
  SortDirection,
  SortInput,
  SortOption,
  WorkoutStatus,
} from "../__generated__/graphql";
import {
  Dropdown,
  DropdownItemProps,
  Form,
  Input,
  Segment,
  Sidebar,
} from "semantic-ui-react";
import { convertEnumForDropdown } from "../formatters/enumConverter";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { FilterOnChange, InputFilters } from "../types/InputWorkoutFilters";

export type FilterSidebarProps = {
  filters: InputFilters;
  className?: string;
  onFilterChange: FilterOnChange;
};

export const FilterSidebar: FC<FilterSidebarProps> = ({
  filters,
  onFilterChange,
}) => {
  const convertSortOption = (sort: SortInput): DropdownItemProps => {
    return {
      key: `${sort.option}_${sort.direction}`,
      value: `${sort.option}_${sort.direction}`,
      text: `${sort.option} (${sort.direction})`,
    };
  };

  const createSortInputs = (): SortInput[] => {
    const result = Object.keys(SortOption).flatMap((sortOption) =>
      Object.keys(SortDirection).map((direction) => {
        return {
          option: sortOption as SortOption,
          direction: direction as SortDirection,
        };
      })
    );

    return result;
  };

  const handleSortChange = (sortOptionValue: string) => {
    const [option, direction] = sortOptionValue.split("_");
    const sort = {
      option: option as SortOption,
      direction: direction as SortDirection,
    };

    onFilterChange("sort", sort);
  };

  return (
    <Sidebar as={Segment} animation="uncover" direction="left" visible={true}>
      <Form>
        <Form.Group>
          <Form.Field>
            <label>Sort By</label>
            <Input
              input={
                <Dropdown
                  className="dropdown-border"
                  placeholder="Select Sort"
                  selection
                  onChange={(_, d) => handleSortChange(d.value as string)}
                  value={
                    (filters?.sort && convertSortOption(filters.sort).value) ||
                    ""
                  }
                  options={createSortInputs().map((i) => convertSortOption(i))}
                />
              }
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <SemanticDatepicker
            className="pl-0"
            label="Created After"
            onChange={(_, d) =>
              onFilterChange("createdAfter", (d.value as Date)?.getTime())
            }
          ></SemanticDatepicker>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Status</label>
            <Input
              input={
                <Dropdown
                  placeholder="Select Status"
                  className="dropdown-border"
                  multiple
                  selection
                  value={filters?.status || []}
                  onChange={(_, d) =>
                    onFilterChange("status", d.value as WorkoutStatus[])
                  }
                  options={convertEnumForDropdown(WorkoutStatus)}
                />
              }
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </Sidebar>
  );
};
