import { getExerciseTypeConfig } from './../formatters/exerciseTypeFormatter';
import { ExerciseInput, ExerciseType } from './../__generated__/graphql';
import { Exercise } from "../__generated__/graphql";
import { ExerciseTextConfig } from '../formatters/exerciseTypeFormatter';
import _ from 'lodash';

const POOUNDS_FROM_KILO_RATIO = 2.20462

const getRepTextForExercise = (exercise: Exercise | ExerciseInput, config: ExerciseTextConfig): string => {
    // afeiltodo: need to account for rowing which is custom
    if (exercise.reps === -1) {
        return config.maxTextFormat(exercise.repType);
    }

    return config.textFormat(exercise.reps, exercise.repType);

}
const convertLoadDisplay = (weight: number, type: ExerciseType): number => {
    const loadIsWeight = ![ExerciseType.BOX_JUMP].includes(type);
    const value = loadIsWeight ? weight * POOUNDS_FROM_KILO_RATIO : weight
    return _.round(value);
}

const displayLoadString = (exercise: Exercise | ExerciseInput): string => {
    const load = exercise.load ?? 0;
    const womensLoad = exercise.womensLoad ?? 0;
    const defaultsWeight = load > 0 ? convertLoadDisplay(load, exercise.type) : ''
    const womensWeight = womensLoad > 0 ? convertLoadDisplay(womensLoad, exercise.type) : ''
    const heightString = `${exercise.height ? exercise.height : ''}${exercise.womensHeight ? '/' + exercise.womensHeight : ''}`;

    return `${defaultsWeight}${womensWeight ? '/' + womensWeight : ''}${heightString && defaultsWeight ? ',' + heightString : ''}`;
}

const convertExerciseWithDifferentResp = (exercises: (Exercise | ExerciseInput)[]): string => {
    const convertedExercises = exercises.map((exercise) => {
        const loadString = displayLoadString(exercise);
        const config = getExerciseTypeConfig(exercise.type);

        return `${exercise.duration ? exercise.duration + ' seconds ' : ''}${getRepTextForExercise(exercise, config)} ${loadString ? '@ ' + loadString : ''}`;
    });

    return _.join(convertedExercises, "\n");
}

export const convertExcerciseToString = (exercises: (Exercise | ExerciseInput)[]): string => {
    // const typesToReps = _.chain(exercises).keyBy('type').mapValues('reps').value();
    return convertExerciseWithDifferentResp(exercises);
}

export const convertExerciseToInput = (exercise: Exercise): ExerciseInput => {

    return {
        duration: exercise.duration,
        finalRep: exercise.finalRep,
        height: exercise.height,
        load: exercise.load,
        loadType: exercise.loadType,
        repStep: exercise.repStep,
        repType: exercise.repType,
        reps: exercise.reps,
        type: exercise.type,
        womensHeight: exercise.womensHeight,
        womensLoad: exercise.womensLoad,
    }
}